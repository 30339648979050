<template>
    <div class="c-search-page">
      <div v-if="selectedTag" class="c-search-page__selected-tag">
        {{ selectedTag.name }}
        <button @click="clearSelectedTag" class="c-search-page__clear-tag">
          <i class="xp xp-close"></i>
        </button>
      </div>
      <ol v-if="shouldShowTags" class="c-search-page__tags-list">
        <li v-for="tag in tags" :key="tag.id" @click="selectTag(tag)" class="c-search-page__tag">
          {{ tag.name }}
        </li>
      </ol>
      <div v-if="isLoadingResults" class="c-search-page__loading">
        <i class="xp xp-spinner xp--large is-spinning"></i>
      </div>
      <MenuEntry v-if="!isLoadingResults && resultAsMenuEntry" class="c-search-page__results" :entryData="resultAsMenuEntry" />
    </div>
</template>

<script>
import { pageMixin } from '@xpedeo/core'

const LS_TAG_KEY = '7buergisches-search-tag'

export default {
  name: 'XpSearchPage',
  mixins: [pageMixin],
  data () {
    return {
      selectedTag: null,
      isLoadingResults: false,
      resultAsMenuEntry: null
    }
  },
  created () {
    const tag = localStorage.getItem(LS_TAG_KEY)
    if (tag) {
      this.selectedTag = JSON.parse(tag)
    }
  },
  computed: {
    tags () {
      if (!this.$xp.content.pageData) {
        return []
      }
      if (!this.$xp.content.pageData.Tags) {
        return []
      }
      const tagsArray = Object.entries(this.$xp.content.pageData.Tags)
      return tagsArray.map((item) => {
        return {
          id: item[0],
          name: item[1]
        }
      })
    },
    shouldShowTags () {
      return this.tags.length > 0 && !this.selectedTag
    }
  },
  methods: {
    selectTag (tag) {
      this.selectedTag = tag
      localStorage.setItem(LS_TAG_KEY, JSON.stringify(tag))
    },
    clearSelectedTag () {
      this.selectedTag = null
      this.resultAsMenuEntry = null
      localStorage.removeItem(LS_TAG_KEY)
    },
    loadResults () {
      if (!this.selectedTag) {
        return
      }
      // http://siebenbuergisches-museum-xpedeo-2023/xpedeo/api/v1/pages/search/Tag1
      this.isLoadingResults = true
      const url = this.$xp.settings.baseUrl + 'xpedeo/api/v1/pages/search/' + this.selectedTag.name
      this.$xp.http.__axiosInstance.get(url).then((response) => {
        console.log(response.data)
        this.resultAsMenuEntry = {
          Class: 'Menu',
          Type: 'list',
          Json: {
            ShowThumbnails: false
          },
          MenuItems: response.data
        }
        // tags =
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.isLoadingResults = false
      })
    }
  },
  watch: {
    selectedTag: {
      handler () {
        this.loadResults()
      },
      immediate: true
    }
  }

}
</script>
